import {
  Menu,
  MenuButton,
  Avatar,
  MenuList,
  MenuGroup,
  MenuItem,
  MenuDivider,
  useColorMode,
  useColorModeValue,
  Icon,
  Button,
} from '@chakra-ui/react';
import {
  FiMoon,
  FiSun,
  FiUser,
  FiUsers,
  FiLogOut,
  FiShield,
  FiBookOpen,
  FiCornerDownRight,
} from 'react-icons/fi';
import { useAuth } from 'contexts/AuthContext';
import { useGoto } from 'hooks/useGoto';
import { useLogInLogOut } from 'hooks/useLogInLogOut';

export const ProfileMenu = () => {
  const { toggleColorMode } = useColorMode();
  const text = useColorModeValue('Dark', 'Light');
  const SwitchIcon = useColorModeValue(FiMoon, FiSun);
  const { login, logout } = useLogInLogOut({ logOutRedirectTo: '/login' });
  const auth = useAuth();
  const { goto, gotoHandler } = useGoto();
  return (
    <>
      {!auth?.currentUser?.managingOrgs && (
        <Button onClick={() => goto('/create/new')}>Create Event</Button>
      )}
      <Menu>
        <MenuButton
          key={auth?.currentUser?.picture}
          as={Avatar}
          size={{ base: 'xs', md: 'sm' }}
          name={auth?.currentUser?.name}
          src={auth?.currentUser?.picture}
        ></MenuButton>
        <MenuList>
          <MenuGroup title="Profile">
            {!auth?.currentUser ? (
              <MenuItem onClick={login}>
                <Icon
                  as={FiUser}
                  margin="0px 0.375rem 0 0"
                  boxSize={4}
                  color="brand.red"
                />
                Log In
              </MenuItem>
            ) : (
              <>
                <MenuItem onClick={gotoHandler(`/user/dashboard`)}>
                  <Icon
                    as={FiUser}
                    margin="0px 0.375rem 0 0"
                    boxSize={4}
                    color="brand.red"
                  />
                  Dashbaord
                </MenuItem>
                <MenuItem onClick={logout}>
                  <Icon
                    as={FiLogOut}
                    margin="0px 0.375rem 0 0"
                    boxSize={4}
                    color="brand.red"
                  />
                  Log out
                </MenuItem>
              </>
            )}
          </MenuGroup>
          <MenuDivider />
          <MenuGroup title="Organisation">
            <MenuItem onClick={gotoHandler(`/create/new`)}>
              <Icon
                as={FiUsers}
                margin="0px 0.375rem 0 0"
                boxSize={4}
                color="brand.red"
              />
              Create Organisation
            </MenuItem>
            {auth?.currentUser?.managingOrgs?.map(managingOrg => (
              <MenuItem
                key={managingOrg.id}
                onClick={gotoHandler(`/org/${managingOrg.id}/dashboard`)}
              >
                <Icon
                  as={FiCornerDownRight}
                  margin="0px 0.375rem 0 0"
                  boxSize={4}
                  color="brand.red"
                />
                {managingOrg.name}
              </MenuItem>
            ))}
          </MenuGroup>
          <MenuDivider />
          <MenuGroup title="Help">
            <MenuItem onClick={gotoHandler(`/legal/privacy`)}>
              <Icon
                as={FiShield}
                margin="0px 0.375rem 0 0"
                boxSize={4}
                color="brand.red"
              />
              Privacy Policy
            </MenuItem>
            <MenuItem onClick={gotoHandler(`/legal/terms`)}>
              <Icon
                as={FiBookOpen}
                margin="0px 0.375rem 0 0"
                boxSize={4}
                color="brand.red"
              />
              Terms of Service
            </MenuItem>
            <MenuItem onClick={toggleColorMode}>
              <Icon
                as={SwitchIcon}
                margin="0px 0.375rem 0 0"
                boxSize={4}
                color="brand.red"
              />
              {text} Mode
            </MenuItem>
          </MenuGroup>
        </MenuList>
      </Menu>
    </>
  );
};
