import { useToast } from '@chakra-ui/react';
import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from 'contexts/AuthContext';

interface ProtectedRouteProps {
  children: React.ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const auth = useAuth();
  const toast = useToast();
  console.log('ProtectedRoute');
  const location = useLocation();

  const authExpired = (auth?.expiryTime ?? -1) * 1000 > Date.now();
  const isAuthenticated = !!auth?.currentUser && authExpired;

  if (!isAuthenticated) {
    toast({
      title: authExpired ? 'Your session expired' : `You haven't logged in`,
      description: 'You can log in again!',
      status: 'warning',
      duration: 2000,
      isClosable: true,
    });
    // Redirect to login if not authenticated
    return (
      <Navigate
        to={`/login?back=${encodeURIComponent(`${location.pathname}${location.search}${location.hash}`)}`}
        replace
      />
    );
  }

  // Render the children if authenticated
  return <>{children}</>;
};

export default ProtectedRoute;
