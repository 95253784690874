import { Box, BoxProps } from '@chakra-ui/react';
import { To } from 'react-router-dom';
import { useGoto } from 'hooks/useGoto';

export const Goto = (props: BoxProps & { url: To }) => {
  const { url, ...rest } = props;
  const { gotoHandler } = useGoto();
  return (
    <Box {...rest} cursor="pointer" onClick={gotoHandler(url)} id="GoTo"></Box>
  );
};
