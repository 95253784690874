import { Global } from '@emotion/react';
import React from 'react';

// Define your raw CSS
export const GlobalStyles = () => (
  <Global
    styles={`
      .rjsf fieldset input[readonly] {
        color: #000; /* Text color */
        background-color: #fff; /* Background color */
        opacity: 1; /* Full opacity */
        border: 1px solid #ddd; /* Border styling */
        cursor: default;
      }
      .rjsf fieldset select[disabled] {
        color: #000; /* Text color */
        background-color: #fff; /* Background color */
        opacity: 1; /* Full opacity */
        border: 1px solid #ddd; /* Border styling */
        cursor: default;
      }
      @keyframes animateOutline {
        0% {
          outline-width: 1px;
          outline-offset: 0;
          outline-color: rgba(0, 130, 206, 0);
        }

        10% {
          outline-color: rgba(0, 130, 206, 0.75);
        }

        /* The animation finishes at 50% */
        50% {
          outline-width: 7px;
          outline-offset: 4px;
          outline-color: rgba(0, 130, 206, 0);
        }

        100% {
          outline-width: 7px;
          outline-offset: 4px;
          outline-color: rgba(102, 102, 102, 0);
        }
      }
      .card-animation-1,
      .card-animation-2 {
        position: relative;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .card-animation-1 .inner,
      .card-animation-2 .inner {
        position: relative;
        z-index: 1;
        width: 100%;
      }

      @keyframes rotate {
        from {
          transform: rotate(0);
        }

        to {
          transform: rotate(360deg);
        }
      }
      .card-animation-2 .inner {
        margin: 2px;
      }
      .card-animation-1 .inner {
        padding: 1px;
      }

      .card-animation-1::before {
        content: "";
        display: block;
        background: linear-gradient(
          90deg,
          rgb(255, 255, 255) 30%,
          hsla(339, 100%, 55%, 1) 100%
        );
        height: 500px;
        width: 500px;
        position: absolute;
        animation: rotate 5s linear infinite;
        z-index: 0;
      }
      .card-animation-2::before {
        content: "";
        display: block;
        background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgb(255 187 206) 50%, rgba(255, 255, 255, 0) 100%);
        height: 300px;
        width: 100px;
        transform: translate(0);
        position: absolute;
        animation: rotate 5s linear forwards infinite;
        z-index: 0;
        top: 50%;
        transform-origin: top center;
      }

      .animate-border {
        outline-width: 1px;
        outline-offset: 0;
        outline-color: #F62460;
        outline-style: solid;
        animation: animateOutline 2s ease infinite;
      }


      /* 'Forward' transitions */
      ::view-transition-old(root) {
        animation: 90ms cubic-bezier(1, 0, 1, 1) both fade-out,
          300ms cubic-bezier(1, 0, 0.2, 1) both slide-to-left;
      }

      ::view-transition-new(root) {
        animation: 210ms cubic-bezier(0, 0, 0.2, 1) 90ms both fade-in, 300ms
            cubic-bezier(1, 0, 0.2, 1) both slide-from-right;
      }

      /* Overrides for 'back' transitions */
      .back-transition::view-transition-old(root) {
        animation-name: fade-out, slide-to-right;
      }

      .back-transition::view-transition-new(root) {
        animation-name: fade-in, slide-from-left;
      }
    `}
  />
);
