import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useBreakpointValue,
} from '@chakra-ui/react';
import { FaGlobeAmericas } from 'react-icons/fa';
import { LKFlag } from 'components/flags/LK';
import { SGFlag } from 'components/flags/SG';
import type { SupportedCountryFilter } from 'types';
import { countryOptions } from 'types';

const countryFlags: Record<SupportedCountryFilter, any> = {
  SG: <SGFlag boxSize={5} />,
  LK: <LKFlag boxSize={5} />,
  Anywhere: <FaGlobeAmericas size={18} />,
};
export const CountryPicker = ({
  country,
  onChange,
}: {
  country: SupportedCountryFilter;
  onChange: (c: SupportedCountryFilter) => void;
}) => {
  const size = useBreakpointValue({
    base: 'sm',
    md: 'md',
  });
  const value = useBreakpointValue({
    base: countryFlags[country] ?? countryFlags.Anywhere,
    md: countryOptions[country] ?? countryOptions.Anywhere,
  });

  const handleOnClick = (c: SupportedCountryFilter) => {
    onChange(c);
  };
  const handleOnClickHandler = (c: SupportedCountryFilter) => {
    return () => handleOnClick(c);
  };
  return (
    <Menu>
      <MenuButton
        as={Button}
        variant="outline"
        size={size}
        rightIcon={<ChevronDownIcon />}
      >
        {value}
      </MenuButton>
      <MenuList>
        <MenuItem onClick={handleOnClickHandler('Anywhere')}>Global</MenuItem>
        <MenuItem onClick={handleOnClickHandler('LK')}>Sri Lanka</MenuItem>
        <MenuItem onClick={handleOnClickHandler('SG')}>Singapore</MenuItem>
      </MenuList>
    </Menu>
  );
};
