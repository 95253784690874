import { VStack, IconButton } from '@chakra-ui/react';
import {
  FiHome,
  FiUser,
  FiSettings,
  FiChevronRight,
  FiChevronLeft,
  FiBarChart2,
  FiUsers,
} from 'react-icons/fi';
import { useParams } from 'react-router-dom';
import { SidebarNavItem } from 'components/SidebarNavItem';
import { useGoto } from 'hooks/useGoto';

interface SidebarContentProps {
  onClose: () => void;
  isCollapsed: boolean;
  isMobile?: boolean;
  onToggle: () => void;
}

export const SidebarContent: React.FC<SidebarContentProps> = ({
  onClose,
  isMobile,
  isCollapsed,
  onToggle,
}) => {
  const { goto } = useGoto();
  const { organisationId } = useParams();

  const handleClick = (url: string) => {
    goto(url);
    if (isMobile) onClose();
  };
  const handleClickHandler = (url: string) => {
    return () => handleClick(url);
  };

  return (
    <VStack
      align="start"
      color="brand.black"
      w={isMobile ? 'full' : isCollapsed ? '60px' : '200px'}
      spacing={0}
      p={isCollapsed ? 2 : 4}
      position="fixed"
      borderRight="1px solid"
      borderColor="chakra-border-color"
      height="full"
      maxH="full"
    >
      <SidebarNavItem
        icon={FiHome}
        label="Dashbaord"
        isCollapsed={isCollapsed}
        onClick={handleClickHandler(`/org/${organisationId}/dashboard`)}
      />
      <SidebarNavItem
        icon={FiUser}
        label="Profile"
        isCollapsed={isCollapsed}
        onClick={handleClickHandler(`/org/${organisationId}/profile`)}
      />
      <SidebarNavItem
        icon={FiUsers}
        label="Managers"
        isCollapsed={isCollapsed}
        onClick={handleClickHandler(`/org/${organisationId}/managers`)}
      />
      <SidebarNavItem
        icon={FiBarChart2}
        label="Analytics"
        isPro
        isCollapsed={isCollapsed}
      />
      <SidebarNavItem
        icon={FiSettings}
        label="Settings"
        isCollapsed={isCollapsed}
        onClick={handleClickHandler(`/org/${organisationId}/settings`)}
      />
      <IconButton
        icon={isCollapsed ? <FiChevronRight /> : <FiChevronLeft />}
        aria-label="Toggle Sidebar"
        onClick={isMobile ? onClose : onToggle}
        mt={10}
        ml={isCollapsed ? 0 : 7}
        alignSelf={isCollapsed ? 'center' : 'flex-start'}
        variant="outline"
        borderRadius="full"
      />
    </VStack>
  );
};
