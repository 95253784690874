import React, { Suspense } from 'react';
import ProtectedRoute from 'components/ProtectedRoute';
import MainLayout from 'layouts/MainLayout';
import OrgLayout from 'layouts/OrgLayout/OrgLayout';
import UserLayout from 'layouts/UserLayout/UserLayout';
import { lazyImport } from 'utils/lazyImport';

// Lazy-loaded components
const AboutUs = lazyImport('pages/AboutUs/AboutUs');
const Form = lazyImport('pages/Form/Form');
const OrganisationRegistrationForm = lazyImport(
  'pages/Home/OrganisationRegistrationForm'
);
const PrivacyPolicy = lazyImport('pages/Legal/PrivacyPolicy');
const TermsOfService = lazyImport('pages/Legal/TermsOfService');
const Login = lazyImport('pages/Login/Login');
const CreateNewEvent = lazyImport(
  'pages/org/Dashboard/creatNewEvent/CreateNewEvent'
);
const OrgDashboard = lazyImport('pages/org/Dashboard/Dashboard');

// Orgs
const DashboardLayout = lazyImport('pages/org/Dashboard/DashboardLayout');
const EditForm = lazyImport('pages/org/Dashboard/editForm/EditForm');
const ViewEvent = lazyImport('pages/org/Dashboard/viewEvent/ViewEvent');
const ViewForm = lazyImport('pages/org/Dashboard/viewForm/ViewForm');
const OrgProfile = lazyImport('pages/org/Profile/Profile');
const OrgSettings = lazyImport('pages/org/Settings/Settings');
const Managers = lazyImport('pages/org/Managers/Managers');

// User
const Dashboard = lazyImport('pages/user/Dashboard/Dashboard');
const Profile = lazyImport('pages/user/Profile/Profile');
const Settings = lazyImport('pages/user/Settings/Settings');
const Event = lazyImport('pages/Event/Event');
const Home = lazyImport('pages/Home/Home');

type PageSuspenseProps = {
  children: any;
};
const PageSuspense = ({ children }: PageSuspenseProps) => (
  <Suspense fallback={<div></div>}>{children}</Suspense>
);
const routes = [
  {
    path: '/',
    element: <MainLayout />,
    children: [
      {
        path: '',
        element: (
          <PageSuspense>
            <Home />
          </PageSuspense>
        ),
      },
      {
        path: 'about',
        element: (
          <PageSuspense>
            <AboutUs />
          </PageSuspense>
        ),
      },
    ],
  },
  {
    path: '/',
    element: <MainLayout hideCountryPicker />,
    children: [
      {
        path: 'login',
        element: (
          <PageSuspense>
            <Login />
          </PageSuspense>
        ),
      },
      {
        path: 'create/new',
        element: (
          <PageSuspense>
            <OrganisationRegistrationForm />
          </PageSuspense>
        ),
      },
    ],
  },
  {
    path: '/',
    element: <MainLayout letFullWidth hideCountryPicker />,
    children: [
      {
        path: 'user',
        element: (
          <ProtectedRoute>
            <UserLayout />
          </ProtectedRoute>
        ),
        children: [
          {
            path: 'dashboard',
            element: (
              <PageSuspense>
                <Dashboard />
              </PageSuspense>
            ),
          },
          {
            path: 'profile',
            element: (
              <PageSuspense>
                <Profile />
              </PageSuspense>
            ),
          },
          {
            path: 'settings',
            element: (
              <PageSuspense>
                <Settings />
              </PageSuspense>
            ),
          },
        ],
      },
      {
        path: 'org/:organisationId',
        element: (
          <ProtectedRoute>
            <PageSuspense>
              <OrgLayout />
            </PageSuspense>
          </ProtectedRoute>
        ),
        children: [
          {
            path: 'dashboard',
            element: <DashboardLayout />,
            children: [
              {
                path: '',
                element: (
                  <PageSuspense>
                    <OrgDashboard />
                  </PageSuspense>
                ),
              },
              {
                path: 'event/new',
                element: (
                  <PageSuspense>
                    <CreateNewEvent />
                  </PageSuspense>
                ),
              },
              {
                path: 'event/:eventId',
                element: (
                  <PageSuspense>
                    <ViewEvent />
                  </PageSuspense>
                ),
              },
              {
                path: 'event/:eventId/form/edit',
                element: (
                  <PageSuspense>
                    <EditForm />
                  </PageSuspense>
                ),
              },
              {
                path: 'event/:eventId/form/:formId',
                element: (
                  <PageSuspense>
                    <ViewForm />
                  </PageSuspense>
                ),
              },
            ],
          },
          {
            path: 'profile',
            element: (
              <PageSuspense>
                <OrgProfile />
              </PageSuspense>
            ),
          },
          {
            path: 'settings',
            element: (
              <PageSuspense>
                <OrgSettings />
              </PageSuspense>
            ),
          },
          {
            path: 'managers',
            element: (
              <PageSuspense>
                <Managers />
              </PageSuspense>
            ),
          },
        ],
      },
    ],
  },
  {
    path: 'to/:organisationId/:eventId',
    element: <MainLayout hideCountryPicker />,
    children: [
      {
        path: '',
        element: (
          <PageSuspense>
            <Event />
          </PageSuspense>
        ),
      },
      {
        path: ':formId',
        element: (
          <ProtectedRoute>
            <PageSuspense>
              <Form />
            </PageSuspense>
          </ProtectedRoute>
        ),
      },
    ],
  },
  {
    path: 'legal',
    element: <MainLayout hideCountryPicker h="full" overflowY="auto" />,
    children: [
      {
        path: 'privacy',
        element: (
          <PageSuspense>
            <PrivacyPolicy />
          </PageSuspense>
        ),
      },
      {
        path: 'terms',
        element: (
          <PageSuspense>
            <TermsOfService />
          </PageSuspense>
        ),
      },
    ],
  },
];

export default routes;
