import { Box, Image, Flex, HStack } from '@chakra-ui/react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { Goto } from 'components/GoTo';
import { useGoto } from 'hooks/useGoto';
import type { SupportedCountryFilter } from 'types';
import { MaxWidthBox } from '../components/MaxWidthBox';
import { ProfileMenu } from '../components/ProfileMenu';
import { CountryPicker } from './CountryPicker';

type TopNavProps = {
  letFullWidth?: boolean;
  hideCountryPicker?: boolean;
};
export const TopNav = ({ letFullWidth, hideCountryPicker }: TopNavProps) => {
  const location = useLocation();
  const { goto } = useGoto();

  const [searchParams] = useSearchParams();
  const country = searchParams.get('in') ?? 'Anywhere';

  const setCountryParam = (c: SupportedCountryFilter) => {
    searchParams.set('in', c);
    goto(`${location.pathname}?${searchParams.toString()}`, undefined, {
      replace: true,
    });
  };

  return (
    <Box>
      <Flex
        height="5rem"
        boxShadow="2xl"
        borderBottom="1px solid"
        borderBottomColor="chakra-border-color"
        bg="chakra-body-bg"
        position="fixed"
        top="0"
        width="100%"
        zIndex="docked"
      >
        <MaxWidthBox
          width="full"
          maxW={letFullWidth ? 'full' : '100rem'}
          display="flex"
          justifyContent="space-between"
        >
          <Goto url={`/?in=${country}`}>
            <Image src="/new-logo.png" height="3rem" margin="1rem" />
          </Goto>
          <HStack marginRight="1rem">
            {!hideCountryPicker && (
              <CountryPicker
                country={
                  (searchParams.get('in') as SupportedCountryFilter) ?? 'global'
                }
                onChange={setCountryParam}
              />
            )}
            <ProfileMenu />
          </HStack>
        </MaxWidthBox>
      </Flex>
      <Box height="5rem"></Box>
    </Box>
  );
};
