import {
  Box,
  Flex,
  IconButton,
  useBreakpointValue,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  useDisclosure,
  Heading,
  VStack,
  Stack,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { FiMenu } from 'react-icons/fi';
import { Outlet } from 'react-router-dom';
import { BreadcrumbProvider } from 'contexts/BreadcrumbContext';
import { MainBreadcrumb } from '../../components/MainBreadcrumb';
import { SidebarContent } from './SidebarContent';

const OrgLayout: React.FC = () => {
  console.log('OrgLayout');
  const [isCollapsed, setIsCollapsed] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const isMobile = useBreakpointValue({ base: true, md: false });

  const handleToggle = () => {
    setIsCollapsed(!isCollapsed);
    onClose();
  };
  return (
    <Box h="full" maxH="full" id="OrgLayout">
      {/* Desktop Sidebar */}
      {!isMobile && (
        <SidebarContent
          onClose={onClose}
          isCollapsed={isCollapsed}
          onToggle={() => setIsCollapsed(!isCollapsed)}
        />
      )}

      {/* Mobile Sidebar */}
      {isMobile && (
        <Drawer isOpen={isOpen} placement="left" onClose={onClose} size="xs">
          <DrawerOverlay />
          <DrawerContent>
            <SidebarContent
              isMobile={isMobile}
              onClose={onClose}
              isCollapsed={false}
              onToggle={handleToggle}
            />
          </DrawerContent>
        </Drawer>
      )}

      {/* Main Content Area */}
      <BreadcrumbProvider>
        <Stack
          ml={{ base: 0, md: isCollapsed ? '60px' : '200px' }}
          p="0"
          h="full"
        >
          <Flex
            justify="space-between"
            align="center"
            bg="gray.800"
            color="white"
            p={4}
            borderBottom="1px"
            borderColor="gray.700"
          >
            {isMobile && (
              <IconButton
                icon={<FiMenu />}
                aria-label="Open Menu"
                onClick={onOpen}
                variant="outline"
                colorScheme="whiteAlpha"
                borderRadius="full"
              />
            )}

            <Heading
              as="h1"
              fontSize="lg"
              fontWeight="bold"
              fontFamily={`"Kiwi Maru", sans-serif`}
            >
              <MainBreadcrumb />
            </Heading>
          </Flex>
          <VStack
            mt={0}
            minH="0px"
            h="full"
            maxH="full"
            id="OrgOutlet"
            alignItems="unset"
          >
            <Outlet />
          </VStack>
        </Stack>
      </BreadcrumbProvider>
    </Box>
  );
};

export default OrgLayout;
