import { ChevronRightIcon } from '@chakra-ui/icons';
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from '@chakra-ui/react';
import { useBreadcrumbContext } from 'contexts/BreadcrumbContext';

export const MainBreadcrumb = () => {
  const { breadcrumbs } = useBreadcrumbContext();
  return (
    <Breadcrumb separator={<ChevronRightIcon color="gray.500" />}>
      {breadcrumbs.map((breadcrumb, i) => (
        <BreadcrumbItem>
          <BreadcrumbLink
            href={breadcrumb.path}
            isCurrentPage={i === breadcrumbs.length - 1}
          >
            {breadcrumb.label}
          </BreadcrumbLink>
        </BreadcrumbItem>
      ))}
    </Breadcrumb>
  );
};
