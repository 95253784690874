import { flushSync } from 'react-dom';
import { NavigateOptions, To, useNavigate } from 'react-router-dom';

const transitionViewIfSupported = (updateCb: () => void) => {
  if (document.startViewTransition) {
    document.startViewTransition(updateCb);
  } else {
    updateCb();
  }
};
export const useGoto = () => {
  const nav = useNavigate();
  const goto = (
    url: To,
    e?: { preventDefault: () => void },
    o?: NavigateOptions
  ) => {
    e?.preventDefault();
    transitionViewIfSupported(() => {
      flushSync(() => {
        nav(url, o);
      });
    });
  };
  const gotoHandler =
    (url: To) => (e: { preventDefault: () => void }, o?: NavigateOptions) =>
      goto(url, e, o);
  return { goto, gotoHandler };
};
