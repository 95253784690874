import React, { createContext, useContext, useState, ReactNode } from 'react';

// Define the shape of each breadcrumb item and the context state
interface BreadcrumbItem {
  label: string;
  path: string;
}

interface BreadcrumbContextType {
  breadcrumbs: BreadcrumbItem[];
  setBreadcrumbs: (items: BreadcrumbItem[]) => void;
  addBreadcrumb: (item: BreadcrumbItem) => void;
  clearBreadcrumbs: () => void;
}

// Create the context
const BreadcrumbContext = createContext<BreadcrumbContextType | undefined>(
  undefined
);

// Create a provider component
interface BreadcrumbProviderProps {
  children: ReactNode;
}

export const BreadcrumbProvider: React.FC<BreadcrumbProviderProps> = ({
  children,
}) => {
  const [breadcrumbs, setBreadcrumbsState] = useState<BreadcrumbItem[]>([]);

  const setBreadcrumbs = (items: BreadcrumbItem[]) => {
    setBreadcrumbsState(items);
  };

  const addBreadcrumb = (item: BreadcrumbItem) => {
    setBreadcrumbsState(prevBreadcrumbs => [...prevBreadcrumbs, item]);
  };

  const clearBreadcrumbs = () => {
    setBreadcrumbsState([]);
  };

  return (
    <BreadcrumbContext.Provider
      value={{ breadcrumbs, setBreadcrumbs, addBreadcrumb, clearBreadcrumbs }}
    >
      {children}
    </BreadcrumbContext.Provider>
  );
};

// Custom hook to use the BreadcrumbContext
export const useBreadcrumbContext = (): BreadcrumbContextType => {
  const context = useContext(BreadcrumbContext);
  if (!context) {
    throw new Error(
      'useBreadcrumbContext must be used within a BreadcrumbProvider'
    );
  }
  return context;
};
