import React from 'react';

export const lazyImport = (path: string, exportName: string = 'default') => {
  // Prefetch the chunk
  import(/* webpackPrefetch: true */ `../${path}`);
  return React.lazy(() =>
    import(`../${path}`).then(mod => ({
      default: exportName === 'default' ? mod.default : mod[exportName],
    }))
  );
};
