export type SpeakerProfile = {
  id: string;
  name: string;
  email: string;
  picture: string;
  country: string;
  bio: string;
  linkedIn: string;
};

export type User = {
  name: string;
  email: string;
  picture: string;
  managingOrgs: Manages[];
};

export type EventType =
  | 'WALK_IN'
  | 'JUST_REGISTER'
  | 'BY_INVITATION'
  | 'BUY_TICKET'
  | 'INVITEES_BUY_TICKET'
  | 'INVITEES_REGISTER';

export interface EventTypeOption {
  id: EventType;
  title: string;
  description: string;
  isPro?: boolean;
}
export type Event = {
  id: string;
  name: string;
  description: string;
  organisationId: string;
  organisationName: string;
  image: string;
  eventDate: string;
  startTime: string;
  endTime: string;
  country: string;
  location: string;
  maxRegistrations: number;
  eventType: EventType;
};
export type UserEventInvitation = {
  id: string;
  name: string;
  organisationId: string;
  image: string;
  eventDate: string;
  startTime: string;
};

export type Form = {
  id: string;
  name: string;
  schema: any;
  uiSchema: any;
  responsesCount?: number;
};

export type ResponseKeys = string[];

export type Response = {
  id: string;
  userId: string;
  formId: string;
  eventId: string;
  organisationId: string;
  data: any;
};

export type SupportedCountrys = 'SG' | 'LK';
export type SupportedCountryFilter = 'Anywhere' | 'SG' | 'LK';
export type Manages = {
  id: string;
  name: string;
  userId: string;
  username: string;
  role: string;
};

export type AuthResponse = {
  token: string;
  expiryTime: number;
  user: User;
  managingOrgs: Manages[];
};

export const countryCodes: Record<SupportedCountrys, string> = {
  SG: 'Singapore',
  LK: 'Sri Lanka',
};
export const countryOptions: Record<SupportedCountryFilter, string> = {
  ...countryCodes,
  Anywhere: 'Global',
};
