import { createContext, useContext } from 'react';
import { User } from 'types';

type AuthContextProps = {
  currentUser: User | null;
  expiryTime: number | null;
  setExpiryTime: (t: number | null) => void;
  setCurrentUser: (user: User | null) => void;
};
export const AuthContext = createContext<AuthContextProps | null>(null);

export const useAuth = () => useContext(AuthContext);
