import { useGoogleLogin } from '@react-oauth/google';
import { useAuth } from 'contexts/AuthContext';
import { useGoto } from 'hooks/useGoto';
import { AuthResponse } from 'types';
import { api } from 'utils/api';

export const useLogInLogOut = ({
  logInRedirectTo,
  logOutRedirectTo,
}: {
  logInRedirectTo?: string;
  logOutRedirectTo?: string;
}) => {
  const auth = useAuth();
  const { goto } = useGoto();
  const logout = () => {
    localStorage.removeItem('jwt');
    localStorage.removeItem('expiryTime');
    localStorage.removeItem('user');
    auth?.setCurrentUser(null);
    auth?.setExpiryTime(-1);
    goto(logOutRedirectTo ?? '/');
  };
  const login = useGoogleLogin({
    flow: 'auth-code',
    onSuccess: async tokenResponse => {
      // Exchange the Google ID token for your app's JWT
      const response = await api
        .post<AuthResponse>('auth/google', {
          searchParams: { code: tokenResponse.code },
        })
        .json();

      const { token, user, expiryTime, managingOrgs } = response;

      localStorage.setItem('jwt', token);
      localStorage.setItem('expiryTime', `${expiryTime}`);
      localStorage.setItem('user', JSON.stringify({ ...user, managingOrgs }));
      auth?.setCurrentUser({ ...user, managingOrgs });
      auth?.setExpiryTime(expiryTime);
      if (logInRedirectTo) goto(logInRedirectTo);
    },
  });

  return { login, logout };
};
