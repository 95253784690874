import { Text, HStack, Button, Badge, Tooltip } from '@chakra-ui/react';

interface SidebarNavItemProps {
  icon: React.ElementType;
  label: string;
  isCollapsed: boolean;
  onClick?: () => void;
  isPro?: boolean;
}

export const SidebarNavItem: React.FC<SidebarNavItemProps> = ({
  icon: Icon,
  label,
  isCollapsed,
  onClick,
  isPro,
}) => (
  <HStack w="full" p={3} justify={isCollapsed ? 'center' : 'start'} spacing={4}>
    <Tooltip
      hasArrow
      isDisabled={!isPro}
      label="This is a Pro feature!"
      placement="right"
    >
      <Button
        variant="ghost"
        leftIcon={<Icon />}
        iconSpacing="0"
        color="brand.red"
        onClick={onClick}
        disabled={!onClick}
      >
        {!isCollapsed && (
          <Text fontSize="md" marginLeft="3px" color="brand.black">
            {label}{' '}
            {isPro && (
              <Badge
                variant="outline"
                colorScheme="purple"
                fontSize="0.6rem"
                borderRadius="full"
              >
                PRO
              </Badge>
            )}
          </Text>
        )}
      </Button>
    </Tooltip>
  </HStack>
);
